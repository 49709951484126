<template>
  <b-container
    fluid
  >
    <b-row>
      <b-col
        cols="12"
      >
        <b-row>

          <b-col
            cols="12"
            md="6"
            class="px-1"
          >
            <b-form-group
              label="Fiscal Year"
              label-for="filter_budgets_fiscal"
              label-class="font-weight-bolder"
              class="mb-2"
            >
              <b-input
                id="filter_budgets_fiscal"
                v-model="itemCenter.fiscal_name"
                type="text"
                disabled
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="6"
            class="px-1"
          >
            <b-form-group
              label="Budget Center"
              label-for="filter_budgets_budget"
              label-class="font-weight-bolder"
              class="mb-2"
            >
              <b-input
                id="filter_budgets_budget"
                v-model="itemCenter.budget_name"
                type="text"
                disabled
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            class="px-1"
          >
            <b-form-group
              label="Item Center"
              label-for="filter_budgets_item"
              label-class="font-weight-bolder"
              class="mb-2"
            >
              <b-textarea
                id="filter_budgets_item"
                v-model="itemCenter.item_name"
                rows="2"
                max-rows="3"
                maxlength="255"
                disabled
              />
            </b-form-group>
          </b-col>

        </b-row>
        <b-row
          v-if="currentTabIndex === 0"
        >
          <b-col
            cols="12"
            class="px-1"
          >
            <b-table
              ref="tableBudget"
              hover
              small
              striped
              bordered
              responsive
              sticky-header="calc(100vh - 26rem)"
              show-empty
              :stacked="isMobile()"
              :per-page="tableBudget.perPage"
              :current-page="tableBudget.currentPage"
              :items="tableBudgetProvider"
              :fields="tableBudget.fields"
              :sort-by.sync="tableBudget.sortBy"
              :sort-desc.sync="tableBudget.sortDesc"
              :sort-direction="tableBudget.sortDirection"
              :filter="tableBudget.filter"
              :filter-included-fields="tableBudget.filterOn"
              :busy="tableBudget.busy"
            >
              <template #cell(index)="row">
                {{ tableBudget.currentPage * tableBudget.perPage - tableBudget.perPage + (row.index + 1) }}
              </template>

              <template #cell(active)="row">
                {{ Number(row.value) === 1 ? 'Yes' : 'No' }}
              </template>

              <template #cell(is_reversal)="row">
                {{ Number(row.value) === 1 ? 'Yes' : 'No' }}
              </template>

              <template #cell(is_return)="row">
                {{ Number(row.value) === 1 ? 'Yes' : 'No' }}
              </template>

              <template #cell()="row">
                <div class="text-md-nowrap">
                  {{ row.value }}
                </div>
              </template>

              <template #cell(credit_amount)="row">
                <div
                  class="text-md-nowrap text-right"
                  style="min-width: 100px"
                >
                  {{ row.value }}
                </div>
              </template>

              <template #cell(debit_amount)="row">
                <div
                  class="text-md-nowrap text-right"
                  style="min-width: 100px"
                >
                  {{ row.value }}
                </div>
              </template>

              <template #cell(action)="row">
                <div class="text-md-nowrap d-flex flex-column flex-md-row justify-content-center">
                  <b-button
                    size="sm"
                    :variant="row.detailsShowing ? 'danger' : 'success' "
                    @click="row.toggleDetails"
                  >
                    {{ row.detailsShowing ? 'Hide' : 'Show' }} Remarks
                  </b-button>
                </div>
              </template>

              <template #row-details="row">
                <b-form-group
                  label="Remarks"
                  :label-for="`remarks-${row.index}`"
                  label-class="font-weight-bolder"
                  class="mb-2"
                >
                  <b-textarea
                    :id="`remarks-${row.index}`"
                    v-model="row.item.remarks"
                    rows="5"
                    max-rows="10"
                    class="border-0"
                    readonly
                  />
                </b-form-group>
              </template>

              <template #table-busy>
                <div class="text-center py-5">
                  <b-icon
                    icon="stopwatch"
                    font-scale="5"
                    animation="cylon"
                  />
                  <p class="h3 py-2">
                    <strong>Loading . . .</strong>
                  </p>
                </div>
              </template>

            </b-table>
          </b-col>

          <b-col
            cols="12"
            md="6"
            class="mt-1 mb-2 px-1"
          >
            <b-select
              v-model="tableBudget.perPage"
              :options="tableBudget.pageOptions"
              size="sm"
              class="w-100 w-md-25"
            />
          </b-col>

          <b-col
            cols="12"
            md="6"
            class="d-flex justify-content-center justify-content-md-end mt-1 px-1"
          >
            <b-pagination
              v-model="tableBudget.currentPage"
              :total-rows="tableBudget.totalRows"
              :per-page="tableBudget.perPage"
              first-number
              last-number
              pills
              prev-text="Prev"
              next-text="Next"
              aria-controls="table"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>

  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { AuditBudgetService } from '@/services'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import formatter from '@/mixins/formatter'
import misc from '@/mixins/misc'

export default {

  name: 'BoItemCentersBudgets',

  mixins: [formatter, misc],

  props: {
    busy: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    currentTabIndex: {
      type: Number,
      default: () => {
        return 0
      }
    },
    itemCenter: {
      type: Object,
      default: () => {
        return {
          id: null,
          fiscal_year: null,
          fiscal_name: null,
          budget_center: null,
          budget_name: null,
          item: null,
          item_name: null,
          active: 0
        }
      }
    }
  },

  data () {
    return {
      state: {
        busy: false
      },
      currencyMask: createNumberMask({
        prefix: '',
        allowDecimal: true,
        includeThousandsSeparator: true,
        allowNegative: false,
        decimalLimit: 2
      }),
      maskedBudget: {
        amount: null
      },
      budget: {
        id: null,
        item_center: this.itemCenter.id,
        position: null,
        debit_amount: 0.00,
        credit_amount: 0.00,
        is_reversal: 0,
        is_return: 0,
        amount: null,
        remarks: null
      },
      tableBudget: {
        perPage: 10,
        pageOptions: [5, 10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',
        filter: {
          search: null,
          item_center: this.itemCenter.id
        },
        filterOn: [],
        busy: false,
        fields: [
          { mobile: 0, key: 'index', label: '#', class: 'text-center' },
          { mobile: 4, key: 'action', class: 'text-center' },
          { mobile: 1, key: 'debit_amount', label: 'Debit', formatter: this.numberFormatter },
          { mobile: 2, key: 'credit_amount', label: 'Credit', formatter: this.numberFormatter },
          { mobile: 3, key: 'is_reversal', class: 'text-center' },
          { mobile: 4, key: 'is_return', class: 'text-center' },
          { mobile: 5, key: 'created_at', formatter: this.dateTimeShortFormatter, sortable: true }
        ].sort(this.scaleOnMobile)
      }
    }
  },

  watch: {

    'maskedBudget.amount' (creditAmount) {
      const transformToNumericValue = creditAmount && creditAmount.length ? creditAmount.replace(/[^\d.]/g, '') : creditAmount
      this.budget.amount = transformToNumericValue ? parseFloat(transformToNumericValue).toFixed(2) : null
    }

  },

  mounted () {
    core.index()
  },

  methods: {

    async tableBudgetProvider ({ currentPage, perPage, sortBy, sortDesc, filter }) {
      this.tableBudget.busy = true
      return await AuditBudgetService.get(
        this.objectToUrl({
          page: currentPage,
          per_page: perPage,
          sort: sortBy,
          sort_desc: sortDesc,
          filter_text: filter.search,
          item_center_id: filter.item_center
        })
      ).then(({ data }) => {
        this.tableBudget.totalRows = data.total_rows
        return data.items
      }).catch(() => []).finally(() => {
        this.tableBudget.busy = false
      })
    },

    tableBudgetRefresh () {
      this.$refs.tableBudget.refresh()
    },

    async onValidateBudget (event, position, entry = 'Default') {
      event.preventDefault()
      await this.$refs.formBudget.validate().then(
        async allFieldsAreValid => {
          if (allFieldsAreValid) {
            this.swalConfirm({
              html: () => {
                this.budget.position = position

                if (position === 'Credit') {
                  this.budget.credit_amount = this.budget.amount
                  this.budget.debit_amount = 0.00
                } else {
                  this.budget.credit_amount = 0.00
                  this.budget.debit_amount = this.budget.amount
                }

                switch (entry) {
                  case 'Reversal': {
                    this.budget.is_reversal = 1
                    this.budget.is_return = 0
                    return `<h6>Post this as Budget ${entry}?</h6>`
                  }
                  case 'Return': {
                    this.budget.is_reversal = 0
                    this.budget.is_return = 1
                    return `<h6>Post this as Budget ${entry}?</h6>`
                  }
                  default: {
                    this.budget.is_reversal = 0
                    this.budget.is_return = 0
                    return `<h6>Post this Budget as ${position}?</h6>`
                  }
                }
              },
              confirmButtonText: `Post As ${event.target.innerText}`,
              customClass: {
                confirmButton: (position === 'Debit' ? 'btn btn-sm btn-danger' : 'btn btn-sm btn-success')
              },
              preConfirm: () => {
                this.state.busy = true
                return new Promise(resolve => {
                  AuditBudgetService.post(this.budget).then(
                    ({ data: { message } }) => {
                      this.tableBudgetRefresh()
                      this.maskedBudget.amount = null
                      this.budget.position = null
                      this.budget.credit_amount = 0.00
                      this.budget.debit_amount = 0.00
                      this.budget.remarks = null
                      this.budget.is_reversal = 0
                      this.budget.is_return = 0
                      this.$refs.formBudget.reset()
                      this.swalSuccess(message)
                      resolve(message)
                    }
                  ).catch(
                    ({ message }) => {
                      this.$refs.formBudget.setErrors(message)
                      resolve(message)
                    }
                  ).finally(() => {
                    this.state.busy = false
                  })
                })
              }
            })
          } else {
            this.swalInvalid()
          }
        }
      )
    }

  }

}
</script>
